<script lang="ts">
	import { signOut, type User } from 'firebase/auth';
	import { Menu, MenuButton, MenuItems, MenuItem } from '@rgossiaux/svelte-headlessui';
	import { auth } from '$lib/firebase';
	import authStore from '$lib/authStore';
	import posthog from 'posthog-js';

	export let light = true;
	export let fixed: boolean = false;

	export let user: User | undefined | null = undefined;
	export let journeyId: string | undefined = undefined;
	export let userIsOwner: boolean | undefined = undefined;

	async function logout() {
		try {
			await signOut(auth);
		} catch (error: any) {
			console.log(error);
		}
	}
</script>

<div class="flex flex-row z-20">
	<div class="flex flex-row justify-start items-center">
		<Menu>
			<MenuButton
				class={`
						w-8 h-8 flex flex-row justify-center items-center text-md rounded
						${
							light
								? `text-primary-600 bg-primary-100 hover:bg-primary-200`
								: `text-zinc-400 border border-zinc-800 hover:border-zinc-700 bg-zinc-900 hover:bg-zinc-800 hover:text-zinc-300 shadow-sm`
						}
					`}
			>
				<i class="fa-solid fa-bars" />
			</MenuButton>
			<MenuItems
				class={`${
					fixed ? 'fixed' : 'absolute'
				} top-10 left-2 w-52 h-fit flex flex-col justify-start items-center text-zinc-100 bg-navy-900 rounded border border-zinc-800 text-xs shadow-md`}
			>
				<div class="h-1.5" />
				{#if $authStore.isLoggedIn}
					<MenuItem
						disabled
						class="w-[196px] h-[4rem] py-2 px-1 overflow-x-hidden overflow-y-hidden"
					>
						Signed in as
						<div class="h-0.5" />
						<p>
							<b>{$authStore.user?.displayName ? $authStore.user?.displayName : ''}</b>
						</p>
						<p>
							{$authStore.user?.email}
						</p>
					</MenuItem>
				{:else}
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
								: 'w-[196px] h-fit bg-navy-900 rounded'}
					>
						<a href="/login" class="flex flex-row justify-start items-center w-full py-2 px-1">
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-in text-zinc-200" />
							</div>
							<div class="w-1.5"></div>
							Log in
						</a>
					</MenuItem>
				{/if}
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a href="/canvas" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-paintbrush text-cyan-400" />
						</div>
						<div class="w-1.5"></div>
						Canvas
					</a>
				</MenuItem>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<!-- <a href="/pages" class="block w-full py-2 px-1"
						><i class="fa-solid fa-book-open text-[#dab488] pl-1 pr-2.5" />Notebook</a
					> -->

					<a href="/journeys" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-sailboat text-[#dab488]" />
						</div>
						<div class="w-1.5"></div>
						Journeys
					</a>
					<!-- orange 200 to 79.2% lch lightness (cyan-400): #dab488 -->
				</MenuItem>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a href="/library" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-cubes text-rose-400" />
						</div>
						<div class="w-1.5"></div>
						Library
					</a>
				</MenuItem>
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a href="/boards" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-images text-rose-400" />
						</div>
						<div class="w-1.5"></div>
						Boards
					</a>
				</MenuItem>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a
						href="https://discord.gg/5rQP6KTR4X"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
						on:click={(e) => {
							e.preventDefault();
							posthog.capture('click_header_discord_link', {
								user_id: user?.uid,
								journey_id: journeyId,
								is_owner: userIsOwner
							});
							window.open('https://discord.gg/5rQP6KTR4X', '_blank');
						}}
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-brands fa-discord text-[#6d7dcd]" />
						</div>
						<div class="w-1.5"></div>
						Iliad Discord
					</a>
				</MenuItem>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a
						href="/account-settings"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-user-gear text-emerald-400" />
						</div>
						<div class="w-1.5"></div>
						Account settings
					</a>
				</MenuItem>
				<MenuItem
					class={({ active }) =>
						active
							? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
							: 'w-[196px] h-fit bg-navy-900 rounded'}
				>
					<a href="/plan" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-tags text-emerald-400" />
						</div>
						<div class="w-1.5"></div>
						Pricing & Plans
					</a>
				</MenuItem>
				{#if $authStore.isLoggedIn}
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-navy-900 brightness-150 rounded'
								: 'w-[196px] h-fit bg-navy-900 rounded'}
					>
						<a
							href="/"
							class="flex flex-row justify-start items-center w-full py-2 px-1"
							on:click={logout}
						>
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-out text-zinc-400" />
							</div>
							<div class="w-1.5"></div>
							Sign out
						</a>
					</MenuItem>
				{/if}
				<div class="h-1.5" />
			</MenuItems>
		</Menu>
	</div>
</div>
